import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Carousel from "../components/Carousel";

export const query = graphql`
  query {
    allStrapiSeo {
      edges {
        node {
          title
          description
          image {
            publicURL
          }
        }
      }
    }
    allStrapiCarousel {
      edges {
        node {
          slides {
            id
            image {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 1440) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout
    seoOptions={{
      title: data.allStrapiSeo.edges[0].node.title || "Home",
      description: data.allStrapiSeo.edges[0].node.description,
      ogImage: data.allStrapiSeo.edges[0].node.image.publicURL,
    }}
    fullscreen
  >
    <Carousel slides={data.allStrapiCarousel.edges[0].node.slides} />
  </Layout>
);

export default IndexPage;
