import React from "react";
import BackgroundImage from "gatsby-background-image";

import styles from "./Carousel.module.scss";

import Slider from "react-slick";

import "../../assets/css/slick.min.css";
import "../../assets/css/slick-theme.min.css";

import Arrow from "../../images/arrow.svg";

function SampleArrow(props) {
  const { onClick, direction } = props;
  return (
    <div
      className={`${styles.arrow} ${
        direction === "next" ? styles.arrowNext : styles.arrowPrev
      }`}
      onClick={onClick}
    >
      <img src={Arrow} alt="" />
    </div>
  );
}

const Carousel = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 5000,
    fadeIn: false,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleArrow direction="next" />,
    prevArrow: <SampleArrow direction="prev" />,
  };

  return (
    <div className={styles.self}>
      <Slider {...settings}>
        {slides.map(slide => (
          <div className={styles.slideContainer}>
            <BackgroundImage
              className={styles.backgroundSlide}
              fluid={slide.image.childImageSharp.fluid}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
